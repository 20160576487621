<template>
  <div style="background-color: #F7F7F7;padding:20px;min-height: 100vh">
    <b >共查询到{{list.length}}个项目</b>
    <div class="list" v-for="item in list" :key="item.id" >
      <b class="fs-16">{{item.projectName}}</b>
      <div class="color-info display-flex pb-12 pt-12 fs-12">
        <span class="mr-10" style="color: #999999 ">合同编号：{{item.contractNum}}</span>
      </div>
      <div class="item fs-14">
        <div class="mr-10"><img :src="people" class="mr-10"><span style="color: #999999;margin-right: 16px">实施工程师</span><span>{{item.engineerName}}</span></div>
        <div class="mr-10"><img :src="people" class="mr-10" ><span style="color: #999999;margin-right: 16px">合同签订人</span><span>{{item.businessPersonName}}</span></div>
        <div class="mr-10"><img :src="time" class="mr-10"><span style="color: #999999;margin-right: 30px">签订日期</span><span>{{item.signDate}}</span></div>
      </div>
      <Divider/>
      <div class="display-flex jc-space-between ai-center">
        <div  v-if="item.state===0"   class="state0">项目状态：正常</div>
        <div  v-if="item.state===1"   class="state1">项目状态：结束</div>
        <div  v-if="item.state===2"   class="state2">项目状态：异常</div>
        <div  v-if="item.state===3"   class="state3">项目状态：暂停</div>
        <div  v-if="item.state===4"   class="state3">项目状态：取消</div>
        <span style="color:#3377FF;font-size: 14px" @click="changeRoute(item)">查看跟进情况</span>
      </div>
    </div>
  </div>
</template>

<script>
import people from '@/views/h5/img/people.png'
import time from '@/views/h5/img/time.png'
export default {
  name: 'ProjectList',
  components: {},
  props: {},
  data () {
    return {
      people,
      time,
      total: 10,
      list: []
    }
  },
  filters: {},
  computed: {},
  watch: {},
  methods: {
    changeRoute (item) {
      console.log(item)
      this.$router.push({
        path: '/h5-search/result/info',
        query: {
          projectId: item.projectId,
          name: item.projectName
        }
      })
    }
  },
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {
    console.log()
    this.list = this.$localStorage.get('list')
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
.list{
  background-color: #FFFFFF;
  margin-bottom: 20px;
  padding: 16px;
  margin-top: 18px;
  &:last-child{
    margin-bottom:0;
  }
  .item{
    >div{
      margin-bottom: 10px;
    }
  }
  .line{
    width: 100%;
    height: 1px;
    margin-bottom: 12px;
    border: 1px solid #EBEBEB;
  }
}
.state0{
  width: 116px;
  height: 28px;
  font-size: 12px;
  text-align: center;
  line-height: 28px;
  background-color: #E9F8EB;
  border-radius: 4px;
  color: #949494;
}
.state1{
  width: 116px;
  height: 28px;
  font-size: 12px;
  text-align: center;
  line-height: 28px;
  background-color:#F7DFDF;
  border-radius: 4px;
  color: #FF3434;
}
.state2{
  width: 116px;
  height: 28px;
  font-size: 12px;
  text-align: center;
  line-height: 28px;
  background-color:#EBEBEB;
  border-radius: 4px;
  color: #949494;
}
.state3{
  width: 116px;
  height: 28px;
  font-size: 12px;
  text-align: center;
  line-height: 28px;
  color: #FF9536 ;
  background-color: #FEF5EC;
  border-radius: 4px;
}

</style>
